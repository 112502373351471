<template>
    <div>
        <HeadContent>
            <img
                    slot="bgImg"
                    src="../../assets/common/topTitleBg.png"
                    style="margin-top: 70px"
            />
            <div slot="title" style="width: 100%"></div>
        </HeadContent>
        <div style="width: 1200px;margin: 0 auto;padding-bottom: 35px;margin-top: 90px;">
            <div class="mtitle">专家团队</div>
        </div>

        <div class="zjinfo">
            <div class="zjinfo-c">
                <div class="zjinfo-l">
                    <img src="./images/蒙版组 223.png" alt="">
                    <div>
                        <span>专家团队</span>
                        <span>刘雄 .</span>
                    </div>
                    <div>
                        产业技术研究院 院长
                    </div>
                    <div>
                        西南大学博士 教授 博士生导师
                    </div>
                </div>
                <div class="zjinfo-r">
                    <ul>
                        <li>教育经历：</li>
                        <div>
                            1993年6月在郑州粮食学院（现河南工业大学）获工学学士。<br>
                            2003年6月，西南大学获硕士学位（农产品加工与储藏专业 食品化学与营养学方向）<br>
                            2006年在西南大学获工学博士学位（农产品加工与储藏专业，食品化学与营养学方向）<br>
                            2004年10月——2007年9月日本爱媛大学留学<br>
                            2007年9月在日本爱媛大学获博士学位（生物资源利用学专业，营养学方向）<br>
                            2008年，晋升西南大学教授。
                        </div>
                    </ul>

                    <ul>
                        <li>教学成果:</li>
                        <div>
                            2003年至2008年间，在国内外核心期刊上发表学术论文40篇，其中SCI 5篇（英文期刊）。主编、副主编教材4部。申请国家发明专利9项，获得国家发明专利3项。<br>
                            2009年度重庆市科技进步二等奖。先后主持国家自然科学基金2项、教育部项目1项、国家星火计划1项、
                            重庆市科委项目3项；主研国家863项目2项、国家星火计划2项、三峡移民科技项目5项。企业委托科研项目30项，开发的新产品如榨菜保鲜与加工系列产品、青稞深加工系列产品、青花椒深加工系列产品、黄花精深加工产品、竹笋深加工产品、微孔淀粉、薯类食品深加工产品等40个，全部转让，实现产业化生产。参与制定重庆市地方标准2项，企业标准12项。
                        </div>
                    </ul>

                    <ul>
                        <li>主要课题:</li>
                        <div>
                            《食品工程原理》《功能性食品》《畜产品加工学》
                        </div>
                    </ul>

                    <ul>
                        <li>科研成果:</li>
                        <div>
                            1.现代食品加工技术；<br>
                            2.粮食、油脂与植物蛋白 ；<br>
                            3.食品营养与化学。
                        </div>
                    </ul>
                </div>
            </div>

        </div>

        <div class="zjinfo">
            <div class="zjinfo-c">
                <div class="zjinfo-l">
                    <img src="./images/蒙版组 226.png" alt="">
                    <div>
                        <span>专家团队</span>
                        <span>汪学荣 .</span>
                    </div>
                    <div>
                        西南大学食品科学学院农产品加工及
                        贮藏工程专业博士
                    </div>
                    <div>
                        西南大学副教授 硕士生导师
                    </div>
                    <div>
                        美国密西西比州立大学访问学者
                    </div>
                </div>
                <div class="zjinfo-r">
                    <ul>
                        <li>教育经历：</li>
                        <div>
                            1998年毕业于四川农业大学食品科学与工程专业，获工学学士学位；<br>
                            2004年毕业于西南农业大学农产品加工及贮藏工程专业，获工学硕士学位；<br>
                            2009年毕业于西南大学农产品加工及贮藏工程专业，获工学博士学位。
                        </div>
                    </ul>

                    <ul>
                        <li>教学成果:</li>
                        <div>
                            主持主研科研项目20余项、西南大学教育教学改革研究项目近10项，编写专著3部，发表核心期刊学术论文30余篇、教育教学改革研究论文10余篇，获得国家发明专利授权4项，撰写的《新时代科技工作者政治参与意识的提升策略研究》获2020年中国科协创新战略研究院、重庆市科协、四川省科协第五届科协改革研讨会征文“二等奖”，曾被评为西南大学“优秀教师”、“优秀班主任”、“优秀实习指导教师”，中国致公党重庆市委员会“优秀党员”、重庆市荣昌区2021年度“最美科技工作者”。
                        </div>
                    </ul>

                    <ul>
                        <li>主要课题:</li>
                        <div>
                            《食品加工原理》《食品工艺学导论》《碳水化合物应用功能及技术开发》
                        </div>
                    </ul>

                    <ul>
                        <li>科研成果:</li>
                        <div>
                            “食品化学与营养学，现代食品加工理论与技术”<br>
                            长期从事畜产品加工与贮藏，畜产品安全与质量控制，畜禽副产物综合利用与开发研究工作，主持国家农业部水禽产业技术体系产品质量标准与检测岗位分项项目2项。<br>
                            主持西南大学博士基金项目1项，主研省部级科研项目6项。<br>
                            承担实验室建设项目1项，在《食品科学》、《农业工程学报》、《食品与发酵工业》和《食品工业科技》等刊物上发表学术论文40余篇。
                        </div>
                    </ul>
                </div>
            </div>

        </div>

        <div class="zjinfo">
            <div class="zjinfo-c">
                <div class="zjinfo-l">
                    <img src="./images/蒙版组 227.png" alt="">
                    <div>
                        <span>专家团队</span>
                        <span>王群 .</span>
                    </div>
                    <div>
                        重庆国科食品检测有限公司 总经理
                    </div>
                    <div>
                        食品安全与质量高级工程师
                    </div>
                </div>
                <div class="zjinfo-r">
                    <ul>
                        <li>工作经历：</li>
                        <div>
                            重庆国科食品检测有限公司总经理，曾任职于安徽阜阳市产品质量监督检验所和安徽阜阳市食品药品检验检测中心，有30多年食品检验技术和质量管理经验，熟悉食品安全管理体系和标准，长期为食品生产企业和检验检测机构实验室提供审核、认证等服务。
                        </div>
                    </ul>

                    <ul>
                        <li>科研成果:</li>
                        <div>
                            食品安全与质量高级工程师 <br>
                            国家注册（食品）审查员 <br>
                            实验室资质认定（CMA）评审员 <br>
                            食品检验检测机构资质认定评审员 <br>
                            实验室认可（CNAS）评审员
                        </div>
                    </ul>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
    export default {
        name: "specialist"
    }
</script>

<style scoped lang="scss">
    .zjinfo {
        margin-bottom: 100px;
        width: 100%;
        padding: 57px 0 38px;
        background: #FBFBFB;
        .zjinfo-c {
            @include page1200;
            display: flex;
        }
        .zjinfo-l {
            width: 364px;
            display: flex;
            flex-direction: column;
            position: relative;
            padding-right: 45px;

            &:after {
                position: absolute;
                content: "";
                right: 0;
                top: 0;
                width: 1px;
                height: 100%;
                background-color: #707070;
            }
            img {
                width: 310px;
                height: 310px;
                border-radius: 50%;
                background-color: #F5F5F5;
            }
            div {
                padding: 0 40px;
                margin-top: 10px;
                width: 300px;
                height: 57px;
                display: flex;
                justify-content: space-between;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
                background: #F5F5F5;
                align-items: center;

                &:nth-child(2) {
                    margin-top: 50px;
                    padding: 0;
                    padding-left: 40px;
                    background: #1320E8;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: bold;
                    align-items: center;
                    color: #FFFFFF;
                    span:last-child {
                        padding-right: 100px;
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: bold;
                        color: #FFFFFF;
                    }
                }
            }
        }

        .zjinfo-r {
            margin-left: 60px;
            ul {
                padding-bottom: 18px;
            }
            ul > li {
                font-size: 24px;
                font-family: PingFang SC;
                font-weight: bold;
                color: #333333;
            }
            div {
                margin-top: 12px;
                font-size: 14px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #333333;
            }
        }
    }
</style>